<template>
  <div class="favq">
    <heand :tagnum="tagnum"></heand>

    <div class="enterprise clearfix">
      <div class="left">
        <ziyuannavleft />
      </div>
      <div class="right">
        <div class="soso_box">
          <h1 class="zhaosh_tit">项目上报追踪</h1>
          <ul class="ipt">
            <li>
              行业：
              <el-select
                class="iipp"
                v-model="valuetype"
                placeholder="请选择"
                @change="labelchange($event,industry,1)"
              >
                <el-option
                  v-for="(item,index) in industry"
                  :key="index"
                  :label="item.name"
                  :value="item.indu_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              投资额：
              <el-select
                class="iipp"
                v-model="valueaptitude"
                placeholder="请选择"
                @change="labelchange($event,invest,2)"
              >
                <el-option
                  v-for="(item,index) in invest"
                  :key="index"
                  :label="item.invest"
                  :value="item.invest_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              项目进度：
              <el-select
                class="iipp"
                v-model="valuecapital"
                placeholder="请选择"
                @change="labelchange($event,stage,3)"
              >
                <el-option
                  v-for="(item,index) in stage"
                  :key="index"
                  :label="item.name"
                  :value="item.stage_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              报送单位：
              <el-select
                class="iipp"
                v-model="valuestate"
                placeholder="请选择"
                @change="labelchange($event,unit,5)"
              >
                <el-option
                  v-for="item in unit"
                  :key="item.unit_id"
                  :label="item.name"
                  :value="item.unit_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              查看方式：
              <el-select
                class="iipp"
                v-model="valueway"
                placeholder="查看全部"
                @change="labelchange($event,optionsway,4)"
              >
                <el-option
                  v-for="item in optionsway"
                  :key="item.way_id"
                  :label="item.name"
                  :value="item.way_id"
                ></el-option>
              </el-select>
            </li>
            <li>
              搜索：
              <el-input v-model="keyword" placeholder="搜索企业名称或项目产品名称"></el-input>
            </li>
            <li>
              <span class="seach" @click="querycondition">搜索</span>
            </li>
          </ul>
        </div>

        <div class="project_main">
          <dl class="project_tit">
            <dt>项目列表</dt>
            <dd>
              <span class="xiaz_btn" @click="getdownReport()">
                <a>批量下载</a>
              </span>
            </dd>
          </dl>
          <div class="project_tables">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
              <tr>
                <td style="width:3%;">
                  <el-checkbox v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
                </td>
                <td style="width:6%">序号</td>
                <td style="width: 13%;text-align:left;">企业名称</td>
                <td style="width: 15%;text-align:left;">项目名称</td>
                <td style="width:7%;">投资产品</td>
                <td style="width:7%;">所在行业</td>
                <td style="width:10%;">投资金额</td>
                <td style="width:7%;">项目进度</td>
                <td style="width:7%;">报送单位</td>
                <td style="width:7%;">项目对接人</td>
                <td>操作</td>
                <td>状态</td>
              </tr>
              <tr v-for="(item,index) in tabledata" :key="index">
                <td>
                  <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                    <el-checkbox :label="item.item_id"></el-checkbox>
                  </el-checkbox-group>
                </td>
                <td>{{index+1}}</td>
                <td style="text-align:left;">{{item.company}}</td>
                <td style="text-align:left;">{{item.name}}</td>
                <td>{{item.product}}</td>
                <td>{{item.industry}}</td>
                <td>{{item.invest}}亿元（人民币）</td>
                <td>{{item.stage}}</td>
                <td>{{item.unit}}</td>
                <td>{{item.link}}</td>
                <td>
                  <span
                    class="chengzi_btn"
                    @click="$router.push({name:'projectgldetsils',query:{id:item.item_id}})"
                  >查看</span>
                  <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click"
                    :ref="'popsh'+item.item_id"
                  >
                    <p style="font-size:16px; font-weight:700; text-align:center;">是否通过此项目</p>
                    <div class="btn">
                      <el-button id="but2" @click="getauditItem(item.item_id, 3)">驳回</el-button>
                      <el-button id="but1" @click="getauditItem(item.item_id, 4)">通过</el-button>
                    </div>
                    <el-button slot="reference" class="lvzi_btn">审核</el-button>
                  </el-popover>
                  <el-popover
                    placement="bottom"
                    width="300"
                    trigger="click"
                    :ref="'popsc'+item.item_id"
                  >
                    <p style="font-size:16px; font-weight:700; text-align:center;">确定删除此项目？</p>
                    <div class="btn">
                      <el-button id="but2" @click="getclear(item.item_id)">取消</el-button>
                      <el-button id="but1" @click="alldelete(item.item_id)">确定</el-button>
                    </div>
                    <el-button slot="reference" class="redzi_btn">删除</el-button>
                  </el-popover>
                </td>
                <td>{{item.status}}</td>
              </tr>
            </table>
          </div>
          <div class="block">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="Number(total)"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listReportItem,
  reportItem,
  auditItem,
  deleteReport,
  downReport
} from "@/api/index";
export default {
  data() {
    return {
      valuetype: "",
      valuestate: "",
      valueaptitude: "",
      valuecapital: "",
      optionsway: {},
      valueway: "",
      tagnum: 7,
      industry: [], // 能源
      invest: [], // 投资额
      stage: [], // 进度
      unit: [], // 部门
      industryid: "", // 能源
      investid: "", // 投资额
      stageid: "", // 进度
      unitid: "", // 部门
      wayid: "",
      keyword: "",
      page: 1,
      perpage: 10,
      checked: "",
      currentPage1: 1,
      way: "",
      tabledata: [],
      page: 1,
      perpage: 10,
      total: "",
      checkedCities: [],
      checkAll: false
    };
  },
  created() {
    this.getlistReportItem();
    this.getreportItem();
  },
  methods: {
    //全选
    handleCheckAllChange(val) {
      var checkeall = [];
      for (var i = 0; i < this.tabledata.length; i++) {
        checkeall.push(this.tabledata[i].item_id);
      }
      this.isIndeterminate = false;
      this.checkedCities = val ? checkeall : [];
    },
    // 取消全选
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.tabledata.length;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
    },
    querycondition() {
      this.getreportItem();
    },
    async getlistReportItem() {
      const res = await listReportItem();
      this.industry = res.industry;
      this.invest = res.invest;
      this.stage = res.stage;
      this.unit = res.unit;
      this.optionsway = res.way;
    },
    async getreportItem() {
      const res = await reportItem({
        indu_id: this.industryid, //行业id,
        invest_id: this.investid, // 投资额id "stage_id": 项目进度id,
        stage_id: this.stageid, //项目进度id,
        unit: this.unitid, // 报送单位id,
        way_id: this.wayid, // 查看方式id,
        search: this.keyword, // 搜索框的内容,
        order: this.order, // 排序方式1 - 正序， 2 为倒序
        page: this.page,
        perpage: this.perpage
      });
      this.tabledata = res.data_list;
      this.total = res.maxnum;
    },
    async getauditItem(id, num) {
      const res = await auditItem({
        item_id: id, // -- 项目id,
        status: num // : 4-通过，3-驳回
      });
      let refName = "popsh" + id;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.$message({
          message: res.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    // 删除
    async alldelete(id) {
      const res = await deleteReport({ item_id: id });
      let refName = "popsc" + id;
      this.$refs[refName][0].doClose();
      if (res.code == 0) {
        this.getreportItem();
        this.$message({
          message: res.msg,
          type: "success"
        });
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
    },
    getclear(id) {
      let refName = "popsc" + id;
      this.$refs[refName][0].doClose();
    },
    labelchange(e, objx, num) {
      let obj = {};
      switch (num) {
        case 1:
          this.industryid = e;
          break;
        case 2:
          this.investid = e;
          break;
        case 3:
          this.stageid = e;
          break;
        case 4:
          this.wayid = e;
          break;
        case 5:
          obj = objx.find(item => {
            return item.unit_id === e; //筛选出匹配数据
          });
          this.unitid = obj.name;
          break;
      }
    },
    async getdownReport() {
      if (this.checkedCities.length == 0) {
        this.$message({
          message: "请选择下载项目",
          type: "error"
        });
        return false
      }
      const res = await downReport({
        data: this.checkedCities
      });
      this.jqDownload(res.url, res.filename);
    },
    jqDownload(url, name) {
      fetch(url).then(res =>
        res.blob().then(blob => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          var filename = name;
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        })
      );
    }
  }
};
</script>

<style lang="less" scoped>
.favq {
  width: 100%;
  background: rgb(243, 243, 243);
  min-height: 100%;
  padding-bottom: 30px;
  li {
    list-style: none;
  }

  .c-top {
    width: 1920px;
    height: 72px;
    background: #fff;

    li {
      display: block;
      width: 100px;
      height: 38px;
      background: #eee;
      margin-top: 19px;
      border-radius: 6px;
      margin-right: 19px;
      font-size: 17px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #666666;
      text-align: center;
      line-height: 38px;
      float: left;
      &:nth-child(1) {
        margin-left: 52px;
      }
      &.active {
        background: rgb(254, 147, 34);
        color: #ffffff;
      }
    }
  }
}
.enterprise {
  padding: 0 30px;
}
.left {
  float: left;
  background: #fff;
  min-width: 170px;

  .dxhj {
    padding-top: 13px;
    width: 100%;
    height: 38px;

    img {
      float: left;
      width: 38px;
      height: 38px;
      margin-left: 13px;
    }

    span {
      float: left;
      line-height: 31px;
      height: 18px;
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #222222;
    }
  }

  /deep/ .el-collapse-item__header {
    height: 40px;
    padding-left: 40px;
    font-size: 14px;
  }

  /deep/ .el-collapse-item__header.is-active {
    color: #fe6a00;
  }
}
.seach {
  display: inline-block;
  height: 30px;
  background: #fe6a00;
  border-radius: 4px;
  line-height: 30px;
  font-size: 14px;
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  margin: 1% 0;
  padding: 0 30px;
  cursor: pointer;
}
.right {
  width: calc(100% - 256px - 15px);
  height: 89vh;
  float: right;
  margin-left: 10px;

  .soso_box {
    background: #fff;
    padding: 22px 10px 10px 22px;
    margin-bottom: 15px;
    overflow: hidden;
    zoom: 1;

    .zhaosh_tit {
      font-size: 18px;
      font-weight: bold;
      text-align: left;
    }

    .ipt {
      overflow: hidden;
      zoom: 1;
      li {
        display: block;
        width: 16.5%;
        height: 31px;
        float: left;
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        margin: 1% 0;

        /deep/.el-select {
          width: 70%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          /deep/.el-input__icon {
            line-height: 30px !important;
          }
        }
        /deep/.el-input {
          width: 100%;
          height: 30px;
          input {
            width: 100%;
            height: 30px;
            color: #333;
            background: #ffffff;
            border: 1px solid #dddddd;
          }
          ::-webkit-input-placeholder {
            color: #333;
          }
          /deep/.el-icon-circle-close {
            background: url("../../assets/merchantsradar/cuo.png") 50% 50%
              no-repeat;
          }
          .el-icon-circle-close:before {
            content: "";
          }
        }
      }

      li:nth-child(2) {
        width: 18%;
        /deep/.el-select {
          width: 65%;
        }
      }
      li:nth-child(3) {
        width: 20%;
        /deep/.el-select {
          width: 60%;
        }
      }
      li:nth-child(4) {
        width: 23%;
        /deep/.el-select {
          width: 67%;
        }
      }
      li:nth-child(5) {
        width: 20%;
        /deep/.el-select {
          width: 62%;
        }
        /deep/.el-input {
          width: 100%;
        }
      }
      li:nth-child(6) {
        width: 25%;
        /deep/.el-input {
          width: 80%;
        }
      }
    }
  }

  .project_main {
    background: #fff;
    padding: 18px 10px 10px 22px;
    min-height: 74%;
    overflow: hidden;
    zoom: 1;

    .project_tit {
      overflow: hidden;
      zoom: 1;
      margin: 0px 0 6px 0;

      dt {
        float: left;
        font-weight: bold;
        font-size: 16px;
        color: #fe6a00;
        height: 30px;
        line-height: 30px;
      }

      dt:before {
        content: " ";
        padding: 0 3px 0 0;
        margin-right: 10px;
        background: #fe6a00;
      }

      dd {
        float: right;

        .xiaz_btn {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          border: 1px solid #fe6a00;
          border-radius: 4px;
          font-size: 14px;
          text-align: center;

          margin: 1% 5px;
          padding: 0 20px;
          a {
            color: #fe6a00;
          }
        }
      }
    }

    .project_tables {
      font-size: 14px;
      line-height: 44px;

      tr:nth-child(odd) {
        background: #f3f6fb;

        td {
          border-top: 1px solid #eee;
          border-bottom: 1px solid #eee;
        }
      }
      span {
        margin: 0 2%;
      }
      .chengzi_btn {
        cursor: pointer;
        color: #fe6a00;
      }
      .lvzi_btn {
        cursor: pointer;
        color: #2cb54c;
      }
      .zizi_btn {
        cursor: pointer;
        color: #135de6;
      }
      .redzi_btn {
        cursor: pointer;
        color: #e51313;
      }
      .bluezi_btn {
        color: #0ebbf3;
      }
      .huangzi_btn {
        color: #e69554;
      }

      /deep/.el-button {
        background: none;
        padding: 0;
        border: 0;
      }
    }

    //分页
    /deep/.el-pagination {
      float: right;
      margin-top: 20px;
    }
    /deep/.btn-prev {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.btn-next {
      border: 1px solid #888;
      padding: 0;
    }
    /deep/.el-pager li {
      border: 1px solid #888;
      margin: 0 3px;
      min-width: 27px;
    }
    /deep/.el-pager li.active,
    /deep/.el-pager li:hover {
      border: 1px solid #fe6a00;
      color: #fe6a00;
    }

    //分页end
  }
}
.btn {
  text-align: center;
  margin-top: 10px;
  #but1 {
    width: 72px;
    height: 30px;
    background: #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    padding: 0;
  }
  #but2 {
    width: 72px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #fe6a00;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 400;
    color: #fe6a00;
    line-height: 30px;
    padding: 0;
  }
}
.project_tables /deep/ .el-checkbox__label {
  display: none;
}
</style>